import React from "react";
import moment from "moment";
import CondominioService from "./condominio";

export default class TicketService {
  static getTicketStatus(ticketData) {
    if (!ticketData) return null;

    var status = "";
    var dateNow = moment();
    var dateVencimento = "";

    if ((ticketData.Duplicatas || []).length) {
      const duplicata = ticketData.Duplicatas.find((duplicata) => duplicata.serie === ticketData.serie);
      
      if(!duplicata) return;

      status = duplicata["situacao"];
      dateVencimento = moment(duplicata["dt-venc"]);
    }

    if (ticketData["dt-cancela"] !== null) return "cancel";
    else if (status === "Quitado") return "paid";
    else if (status === "Aberto" && dateVencimento.isSameOrBefore(dateNow))
      return "outdated";
    else if (status === "Aberto") return "avencer";
    else return "open";
  }

  static getTicketBadge(ticketData) {
    return {
      paid: <span className="badge success">Nota fiscal paga</span>,
      outdated: <span className="badge danger">Nota fiscal vencida</span>,
      open: <span className="badge warning">Aguardando pagamento</span>,
      avencer: <span className="badge warning">Nota fiscal a vencer</span>,
      cancel: (
        <span className="badge cancel">Nota fiscal cancelada/devolvida</span>
      ),
    }[TicketService.getTicketStatus(ticketData)];
  }

  static getValidTickets(tickets) {
    if (!tickets) return null;
    return tickets.filter((i) => i["tt-dados-gerais"] !== undefined);
  }

  static getNextDelivery(itCodigo) {
    if (!CondominioService.selectAll) {
      const selectedDeliveryPlace = CondominioService.selected;

      if (!selectedDeliveryPlace) return null;

      var itens = selectedDeliveryPlace["Itens"];

      if ((itens || []).length)
        for (const item of itens) {
          if ((!itCodigo || item['it-codigo'] === itCodigo) && item["dt-prox-entrega"]) return item;
        }
    }
    return null;
  }

  static getNextDeliveryFromAddress(address) {
    if (!address) return;
    var itens = address["Itens"];
    if ((itens || []).length) {
      for (const item of itens) {
        if (item["dt-prox-entrega"]) return item;
      }
    }
  }

  static getConsumptionValues(ticketData, verifica) {
    var vlunit = "";
    var qt = "";
    var dateVencimento = "";
    var nameItem = [];
    var unidade = "";

    if ((ticketData.Itens || []).length) {
      const item = ticketData.Itens[0];
      vlunit = item["vl-preuni"];
      qt = item["qt-faturada"];
      nameItem = item["desc-item"];
      unidade = item["un"];
    }

    if ((ticketData.Duplicatas || []).length) {
      const duplicata = ticketData.Duplicatas[0];
      dateVencimento = moment(duplicata["dt-venc"]).format("DD/MM/YYYY");
    }

    if (verifica === "unitario") return vlunit;
    else if (verifica === "quantidade") return qt;
    else if (verifica === "vencimento") return dateVencimento;
    else if (verifica === "itens") return nameItem;
    else if (verifica === "un") return unidade;
  }
}
